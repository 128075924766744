import styled from "styled-components"

import { cardBoxShadow } from "src/constants/shadows"
import { mColors } from "src/ui/colors"
import { spacing } from "src/ui/spacing"

export function MCard({
  padding = spacing.M,
  backgroundColor = mColors.neutralLight,
  boxShadow = true,
  border = false,
  children,
  ...props
}: {
  padding?: spacing | 0
  backgroundColor?: string
  boxShadow?: boolean
  border?: boolean
  children: React.ReactNode
}) {
  return (
    <Box
      $padding={padding}
      $backgroundColor={backgroundColor}
      $boxShadow={boxShadow}
      $border={border}
      {...props}
    >
      {children}
    </Box>
  )
}

const Box = styled.div<{
  $padding?: spacing | 0
  $boxShadow?: boolean
  $border?: boolean
  $backgroundColor: string
}>`
  overflow: hidden;
  padding: ${({ $padding }) => $padding};
  border-radius: 16px;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  border: ${({ $border }) =>
    $border ? `1px solid ${mColors.divider};` : "unset"};
  ${({ $boxShadow }) => ($boxShadow ? cardBoxShadow : undefined)};
`
